import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';


const CookieBanner = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(0);
    const [cookies, setCookie] = useCookies(['cookieConsent']);

    useEffect(() => {
        if (!cookies.cookieConsent) {
            setShow(true);
        }
    });

    const getContent = (translation) => {
        return { __html: `${t(translation)}` }
    }

    const handleClick = () => {
        setShow(false);
        const expires = new Date();
        expires.setDate(expires.getDate() + 365);
        setCookie('cookieConsent', true, { expires: expires });
    }


    return <>
        {show ? (
            <div className="cookie-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10">
                            <p><div className="text-white" dangerouslySetInnerHTML={getContent('site.cookie.banner')}></div></p>
                        </div>
                        <div className="col-lg-2 d-flex justify-content-center align-items-center">
                            <button className="btn btn-primary orange" onClick={handleClick}>
                                ОК
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        ) : (<></>)}

    </>
}

export default CookieBanner;