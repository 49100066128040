import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import withTrans from '../i18n/withTranslation';
import Footer from './Footer';
import Header from './Header';
import SEO from './SEO';
import CookieBanner from './CookieBanner';

const FloatingFab = styled.div`
    position: fixed;
    background: ${props => props.bgColor};
    ${props => props.isRight ? `right: 25px;border-radius: 50%;width: 65px;height: 65px;` : `left: 25px;border-radius: 0%;width: 35px;height: 35px;`}
    bottom: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s width, 0.2s height;
    z-index: 100;

    &:hover {
        transition: 0.2s width, 0.2s height;
        ${props => props.isRight ? `width: 70px;height: 70px;` : `background: $orange;`}
    }

    -webkit-box-shadow: 2px 10px 29px -3px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 10px 29px -3px rgba(0,0,0,0.2);
    box-shadow: 2px 10px 29px -3px rgba(0,0,0,0.2);

    @media screen and (max-width: 991px) {
        bottom: 15px;
        ${props => props.isRight ? `right: 15px;width: 55px;height: 55px;` : `left: 15px;width: 30px;height: 30px;`}
    }
`;

const handleScrollTop = () => {
    window.scrollTo({
        top: 0,
        left: 0
    });
}

const Layout = ({ children, isDark, title, description, img }) => {

    const [showChevron, setShowChevron] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const handleScroll = () => {
        setShowChevron(window.scrollY > 600);
    }

    return (
        <div className="relative">
            <SEO
                title={title}
                description={description}
                img={img}
            />
            <Header isDark={isDark} />
            <div>
                <main>{children}</main>
            </div>

            <FloatingFab isRight={true} bgColor="#4fade0">
                <a href='tel:+359876365035'>
                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-telephone" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                </a>
            </FloatingFab>

            {showChevron && (
                <FloatingFab isRight={false} bgColor="#616161" onClick={handleScrollTop}>
                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-chevron-up" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                    </svg>
                </FloatingFab>

            )}

            <CookieBanner />

            <Footer />
        </div>
    )
}

export default withTrans(Layout);