import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql, Link } from 'gatsby';

export default () => {

    const { t } = useTranslation();

    const data = useStaticQuery(graphql`
        query Footer {
            lightLogo: file(relativePath: {eq: "logo-white.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
        }`
    );
    const lightLogo = data.lightLogo.childImageSharp.fixed.src;

    return (
        <>
            <footer>
                <div className="upper-footer">
                    <div className="container py-3">

                        <div className='row d-flex justify-content-center my-5'>
                            <div className="col-lg-5 mb-5">
                                <h5 className="mb-4">{t('site.footer.about.title')}</h5>
                                <p className="mb-4">{t('site.footer.about.text')}</p>

                                <img src={lightLogo} alt={t('site.logo.alt')} className="img-fluid" />
                            </div>
                            <div className="col-lg-7 mb-5 d-flex justify-content-lg-end">
                                <div className="px-lg-5 w-100">
                                    <h5 className="mb-4">{t('site.footer.navigation.title')}</h5>

                                    <ul className="footer-navigation text-white">
                                        {t('site.footer.navigation.links').map((el, index) => {
                                            return <li key={index} className="mr-5">
                                                <Link to={el.link}>{el.title}</Link>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center mt-5 text-center">
                                <a href="tel:+359886609877">+359 886 609 877</a>
                                <a href="https://www.facebook.com/ostreceu/" target="_blank" rel="noopener noreferrer" className="mx-5"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" /></svg></a>
                                <a href='tel:+359876365035'>+359 876 365 035</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lower-footer">
                    <div className="container py-3">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-left">
                                {t('site.footer.footerText')} © 2018 - {new Date().getFullYear()}
                            </div>
                            <div className="col-md-6 text-center text-md-right mt-4 mt-md-0">
                                {t('site.footer.madeBy')} <a href="https://www.marketiseme.com/" target="_blank" rel="noopener noreferrer">Marketise Me</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}