import classNames from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageMenu from './LanguageMenu';

const Header = ({ isDark }) => {

    const { t } = useTranslation();
    const [isDarkHeader, setIsDarkHeader] = useState(isDark);

    const data = useStaticQuery(graphql`
        query Header {
            darkLogo: file(relativePath: {eq: "logo-dark.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            lightLogo: file(relativePath: {eq: "logo-white.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
        }`
    );



    const handleScroll = () => {
        if (isDark) {
            if (window.scrollY > 150) {
                setIsDarkHeader(false);
            } else {
                setIsDarkHeader(true);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);


    const darkLogo = data.darkLogo.childImageSharp.fixed.src;
    const lightLogo = data.lightLogo.childImageSharp.fixed.src;

    return (
        <>
            <nav
                className={
                    classNames(
                        "navbar navbar-expand-lg navbar-light bg-light fixed-top custom-header px-2",
                        { "dark bg-lg-dark navbar-lg-dark ": isDarkHeader })
                }>
                <div className="container d-flex justify-content-between align-items-center">
                    <Link to='/'>
                        <img src={isDarkHeader ? lightLogo : darkLogo} className="img-fluid d-none d-lg-block" alt={t('site.logo.alt')} />
                        <img src={darkLogo} className="img-fluid d-lg-none" alt={t('site.logo.alt')} />
                    </Link>


                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end text-center" id="navbarNavDropdown">
                        <ul className="navbar-nav">

                            {t('site.header.pageLinks').map((el, index) => {
                                if (el.subcategories) {
                                    return <li className="nav-item dropdown" key={"index" + index}>
                                        <span className="nav-link dropdown-toggle" id={"navbarDropdownMenuLink" + index} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {el.title}</span>
                                        <div className="dropdown-menu" aria-labelledby={"navbarDropdownMenuLink" + index}>
                                            {el.subcategories.map((subcat, subindex) => {
                                                return (
                                                    <Link to={subcat.link} className="dropdown-item" key={"subindex" + subindex}>{subcat.title}</Link>
                                                )
                                            })}
                                        </div>
                                    </li>
                                } else {
                                    return <li className="nav-item" key={index}>
                                        <Link className="nav-link" to={el.link}>{el.title}</Link>
                                    </li>
                                }
                            })}
                        </ul>
                        <LanguageMenu />
                    </div>
                </div>
            </nav >
        </>
    )
}

Header.defaultProps = {
    isDark: false,
}

Header.propTypes = {
    isDark: PropTypes.bool
}

export default Header;
