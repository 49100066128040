import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import bgFlag from '../assets/images/bg.svg';
import enFlag from '../assets/images/en.svg';


const LanguageMenu = () => {

    const { i18n } = useTranslation();

    const [lang, setLang] = useState('bg');

    // useEffect(() => {
    //     if (sessionStorage.getItem('current_lang')) {
    //         i18n.changeLanguage(sessionStorage.getItem('current_lang'));
    //         setLang(sessionStorage.getItem('current_lang'))
    //     }
    // }, [window.location]);

    const handleChange = (event) => {
        i18n.changeLanguage(event.currentTarget.value);
        sessionStorage.setItem('current_lang', event.currentTarget.value);
        setLang(event.currentTarget.value);
    }


    return (
        <>
            {lang === 'bg' ?
                (
                    <button value='en' onClick={handleChange} className="btn-flag"><img value='en' src={enFlag} alt="EN" className="nav-flags" /></button>
                ) : (
                    <button value='bg' onClick={handleChange} className="btn-flag"><img value='bg' src={bgFlag} alt="BG" className="nav-flags" /></button>
                )}
        </>
    )
}

export default LanguageMenu;